import consumer from "./consumer"

consumer.subscriptions.create("TimelineSyncChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
    console.log('TimelineSyncChannel connected')
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
    console.log('TimelineSyncChannel disconnected')
  },

  received(data) {
    if(data.progress) {
      document.getElementById('progress').innerHTML = `${parseInt(data.progress * 100)}%`
      document.getElementById('progressBar').style.width = `${parseInt(data.progress * 100)}%`

    } else if(data.finished) {
      document.getElementById('progress').innerHTML = "Finished."
      document.getElementById('progressBar').style.width = '100%'

      if(confirm('Finished getting new Links, reload page?')) window.location = window.location;

    } else if(data.rate_limited) {
      document.getElementById('progress').innerHTML = "Error: Rate Limit Exceeded, please try again later."
    }
  }
});
